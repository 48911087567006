const { differenceInDays } = require("date-fns");

/**
 * Retrieves content from a specified page using a Google Apps Script API.
 * @param {string} [pageName='events'] - The name of the page to retrieve content from.
 * @returns {Promise<any>} - A promise that resolves to the retrieved content.
 */
function getJsonContent(pageName = "events") {
  let url = `/data/${pageName}.json`;
  if (pageName === "events") {
    url = `https://script.google.com/macros/s/AKfycbzCMlzcUE_yVGi-4qjIkLg0gxqVyI_EsVnERlypezRt5iuuzXbZ7_pqILdIPql5rEMB/exec/exec?page=${pageName}`;
  }
  if (process.env?.NODE_ENV === "development") {
    url = `/assets/testEvents.json`;
  }

  return fetch(url)
    .then((response) => response.json())
    .then((out) => {
      // only return events that are today or in the future
      return out.data.filter((event) => {
        const eventDate = new Date(event.date);
        const today = new Date();
        // console.log({ eventDate, today, diff: differenceInDays(eventDate, today)});
        return !isNaN(eventDate) && differenceInDays(eventDate, today) >= 0;
      });
    })

    .catch((error) => console.error("Error:", error));
}

module.exports = { getJsonContent };
