// src/router.js
import { createRouter, createWebHistory } from "vue-router";
import EntrancePageEnglish from "./pages/EntranceEnglish.vue";
import EntrancePagePunjabi from "./pages/EntrancePunjabi.vue";
import HomePage from "./pages/Home.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomePage
  },
  {
    path: "/entranceEnglish",
    name: "EntranceEnglish",
    component: EntrancePageEnglish
  },
  {
    path: "/entrancePunjabi",
    name: "EntrancePunjabi",
    component: EntrancePagePunjabi
  }
];

const router = createRouter({
  publicPath: "/",
  history: createWebHistory(),
  routes,
});

export default router;
