<!-- src/components/EntrancePunjabi.vue -->
<template>
  <div v-if="loading" class="loading-container">
    <div class="spinner"></div>
  </div>
  <h1 class="display-3 text-dark shadow">{{ time }}</h1>
  <div class="table-responsive shadow-lg rounded">
    <table class="table table-striped table-bordered" style="opacity: 0.92">
      <thead class="thead-light table-primary" style="--bs-table-bg: #ffc107">
        <tr>
          <td style="width: 20%">ਤਾਰੀਖ</td>
          <td style="width: 10%">ਸਮਾਂ</td>
          <td style="width: 20%">ਕਮਰਾ</td>
          <td style="width: 20%">ਪ੍ਰੋਗਰਾਮ</td>
          <td style="width: 30%">ਵੇਰਵੇ</td>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in items" :key="item.id" style="vertical-align: middle">
          <td style="height: 100px; vertical-align: middle" class="fs-2">
            {{ translateDateToPunjabi(formatDate(item.date)) }}
          </td>
          <td style="vertical-align: middle" class="fs-2">{{ item.time }}</td>
          <td style="vertical-align: middle" class="fs-1">
            {{ translateRoomToPunjabi(item.roomName.trim()) }}
          </td>
          <td style="vertical-align: middle" class="fs-1">
            {{ translateEventToPunjabi(item.eventType.trim()) }}
          </td>
          <td style="vertical-align: middle" class="fs-1">
            <p class="fs-1">{{ item.title.trim() }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { getJsonContent } from "../data/getJsonContent.js";
import { format } from "date-fns";

export default {
  name: "EntrancePage",
  data() {
    return {
      items: [],
      time: "",
      loading: false,
      reloadInterval: null,
      backgroundImage: "",
      format
    };
  },
  mounted() {
    this.fetchData();

    // Set up interval to refresh data every 120 seconds
    this.reloadInterval = setInterval(() => {
      location.reload();
    }, 1000 * 120);

    setInterval(() => {
      this.time = this.returnTime();
    }, 1000);

    // get random background image
    const imageCount = 27;
    const randomIndex = Math.floor(Math.random() * imageCount);
    this.backgroundImage =
      randomIndex < 10 ? `0${randomIndex}` : randomIndex.toString();
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    clearInterval(this.reloadInterval);
  },
  methods: {
    fetchData() {
      this.loading = true;
      getJsonContent()
        .then((data) => {
          this.items = data;
        })
        .catch((error) => {
          console.error("Error fetching JSON content:", error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatDate(incomingDate) {
      const date = new Date(incomingDate);

      if (isNaN(date.getTime())) {
        console.error("Invalid date format:", incomingDate);
        return "";
      }
      return format(date, "E d MMM");
    },
    translateDateToPunjabi(incomingDate) {
      const punjabiDays = {
        Sun: "ਐਤਵਾਰ",
        Mon: "ਸੋਮਵਾਰ",
        Tue: "ਮੰਗਲਵਾਰ",
        Wed: "ਬੁੱਧਵਾਰ",
        Thu: "ਵੀਰਵਾਰ",
        Fri: "ਸ਼ੁੱਕਰਵਾਰ",
        Sat: "ਸ਼ਨੀਚਰਵਾਰ"
      };

      const punjabiMonths = {
        Jan: "ਜਨਵਰੀ",
        Feb: "ਫ਼ਰਵਰੀ",
        Mar: "ਮਾਰਚ",
        Apr: "ਅਪ੍ਰੈਲ",
        May: "ਮਈ",
        Jun: "ਜੂਨ",
        Jul: "ਜੁਲਾਈ",
        Aug: "ਅਗਸਤ",
        Sep: "ਸਤੰਬਰ",
        Oct: "ਅਕਤੂਬਰ",
        Nov: "ਨਵੰਬਰ",
        Dec: "ਦਸੰਬਰ"
      };

      const [day, date, month] = incomingDate.split(" ");
      const translatedDay = punjabiDays[day] || day;
      const translatedMonth = punjabiMonths[month] || month;
      return `${translatedDay}, ${date} ${translatedMonth}`;
    },
    translateRoomToPunjabi(roomName) {
      const punjabiRoomNames = {
        "Darbar Sahib 1": "ਦਰਬਾਰ ਸਾਹਿਬ ੧",
        "Darbar Sahib 2": "ਦਰਬਾਰ ਸਾਹਿਬ ੨",
        "Darbar Sahib 3": "ਦਰਬਾਰ ਸਾਹਿਬ ੩",
        "Outside Hall": "ਬਾਹਰਲਾ ਹਾਲ",
        "Langar Hall": "ਲੰਗਰ ਹਾਲ",
        "Langar Hall 1": "ਲੰਗਰ ਹਾਲ ੧",
        "Langar Hall 2": "ਲੰਗਰ ਹਾਲ ੨",
        Library: "ਲਾਇਬਰੇਰੀ",
        Kitchen: "ਰਸੋਈ",
        Office: "ਦਫਤਰ",
        Gym: "ਜਿਮ",
        Classroom: "ਕਲਾਸਰੂਮ",
        "Conference Room": "ਕਾਨਫਰੰਸ ਰੂਮ",
        "Storage Room": "ਸਟੋਰੇਜ ਰੂਮ",
        Other: "ਹੋਰ"
      };
      return punjabiRoomNames[roomName] || roomName;
    },
    translateEventToPunjabi(eventType = "") {
      const punjabiEventType = {
        "Sukhmani Sahib": "ਸੁਖਮਨੀ ਸਾਹਿਬ",
        "Arambh Sri Akhand Paath": "ਅਰੰਭ ਸ਼੍ਰੀ ਅਖੰਡ ਪਾਠ",
        "Bhog Sri Akhand Paath": "ਭੋਗ ਸ਼੍ਰੀ ਅਖੰਡ ਪਾਠ",
        "Bhog Sri Sehj Paath": "ਭੋਗ ਸ਼੍ਰੀ ਸਹਿਜ ਪਾਠ",
        "Anand Karaj": "ਆਨੰਦ ਕਾਰਜ",
        "Asa Di Vaar": "ਆਸਾ ਕੀ ਵਾਰ",
        Funeral: "ਅੰਤਿਮ ਸੰਸਕਾਰ",
        "Gurbani Class": "ਗੁਰਬਾਣੀ ਕਲਾਸ",
        "Keertan Darbaar": "ਕੀਰਤਨ ਦਰਬਾਰ",
        Other: "ਹੋਰ"
      };
      return punjabiEventType[eventType] || eventType;
    },
    returnTime() {
      const today = new Date();

      // get time components
      const hours = today.getHours();
      const minutes = today.getMinutes();
      const seconds = today.getSeconds();

      //add '0' to hour, minute & second when they are less 10
      const hour = hours < 10 ? "0" + hours : hours;
      const minute = minutes < 10 ? "0" + minutes : minutes;
      const second = seconds < 10 ? "0" + seconds : seconds;

      //make clock a 12-hour time clock
      const hourTime = hour > 12 ? hour - 12 : hour;

      // if (hour === 0) {
      //   hour = 12;
      // }
      //assigning 'am' or 'pm' to indicate time of the day
      const ampm = hour < 12 ? "AM" : "PM";

      //get current date and time
      const date = format(new Date(), "eeee, do MMMM");
      const time = `${hourTime}:${minute}:${second} ${ampm}`;

      //combine current date and time
      const dateTime = date + " - " + time;
      return dateTime;
    }
  }
};
</script>

<style scoped>
/* Assign grid areas */
.content {
  grid-area: content;
}

/* Add your styles here */
table {
  width: 100%;
  font-size: xx-large;
  border-collapse: collapse;
}

thead {
  font-size: xx-large;
  text-align: center;
  color: red;
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f4f4f4;
  text-align: left;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust as needed */
}

.spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
